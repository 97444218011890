// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-person-js": () => import("/opt/build/repo/web/src/templates/person.js" /* webpackChunkName: "component---src-templates-person-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/web/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/web/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-community-js": () => import("/opt/build/repo/web/src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-environment-js": () => import("/opt/build/repo/web/src/pages/environment.js" /* webpackChunkName: "component---src-pages-environment-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-performance-js": () => import("/opt/build/repo/web/src/pages/performance.js" /* webpackChunkName: "component---src-pages-performance-js" */),
  "component---src-pages-pricing-js": () => import("/opt/build/repo/web/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/web/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/web/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-terms-js": () => import("/opt/build/repo/web/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

